.about {
  .experience {
    display: flex;
    justify-content: center;
    ul {
      li {
        font-size: 1.25em;
      }
    }
  }
}
