.projects {
  &-item {
    background-color: rgba($primary-color, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    h3 {
      flex: 2 0;
      align-self: stretch;
    }
    p {
      padding: 1em; // flex: 1 0;
      // align-self: flex-start;
    }
    ul {
      flex: 3 1 0; // align-self: center;
    }
  }
  .target_button {
    a {
      text-decoration: none;
    }
  }
}
