video {
  position: fixed; // position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%); // background: url('https://res.cloudinary.com/nziswano/image/upload/q_auto:good/v1518513065/IMG_2043.png') no-repeat;
  background-size: cover;
  transition: 1s opacity;
}
