.services {
  h3 {
    border-bottom: 2px solid $secondary-color;
  }
  &-row {
    display: flex;
    justify-content: space-around;
  }
  &-cell {
    text-align: center;
    padding: 1em;
    display: flex;
    flex-direction: column;
  }
  &-section {
    .title-icon {
      font-size: 3em;
    }
    p {
      text-align: left;
    }
  }
  &-section,
  &-benefits,
  &-use-cases {
    display: flex;
    flex-direction: column;
  }
}
