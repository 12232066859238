footer {
  dl {
    dt,
    dd {
      font-weight: 700;
    }
  }

  .copyright {
    display: flex;
    justify-content: center;
    li {
      padding: 0 1rem;
    }
  }
}

.contact-us-form-section {

  .form-error {
    color: $alert-color;
  }
  
  .show-error {
    display: block;
  }

  .help-text {
    color: $secondary-color;
  }
  form {
    label {
      color: $secondary-color;
      font-weight: bolder;
      font-size: 1.25rem;
    }
    input[type="text"],
    [type="email"],
    textarea {
      border: 1px solid $primary-color;
      border-radius: 5px;
    }
  }
}
