a:hover,
a:focus {
  text-decoration: none;
}

button:focus {
  outline: none;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: capitalize;
  color: $secondary-color;
  font-family: 'Patua One';
}

h1 {
  font-size: 4.5rem;
  font-weight: 900;
}

h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

h3 {
  font-size: 2rem;
}

ul {
  list-style: none;
  li {
    text-align: left;
    span {
      margin-right: 1em;
    }
  }
}

section>h2,
.main-header {
  background-color: rgba($primary-color, 0.3);
  border: 3px solid $secondary-color;
  border-top: 2px solid $secondary-color;
  border-bottom: 2px solid $secondary-color;
  border-radius: 0 2em;
  padding: 0.25em 0;
  margin: 0.1em;
}

section>h2 {
  margin: 0.25em 2rem;
}

.front {
  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 70vh;
  }
  .main-header {
    h3 {
      font-style: italic;
      font-size: 1.25em;
    }
    blockquote {
      display: block;
      font-style: italic;
      text-align: center;
      cite {
        text-align: center;
        display: block;
        font-weight: bolder;
      }
    }
  }
}
